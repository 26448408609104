<template>
    <div :class="sizeClass">
        <div class="avatar-name-right-area">
            <el-avatar :src="getPhoto(driver.OtherPictures)" :size="size" fit="fill">
                <img src="../../assets/image/default-photo.svg"/>
            </el-avatar>
            <span class="face-id-icon via-faceID" v-if="driver.HasFeature"></span>
        </div>
        <div>
            <el-link @click="viewDriver(driver)" v-if="isLink">
                <span class="text-overflow-ellipsis" :title="driver.Name">
                    {{driver.FirstName}} {{driver.LastName}}</span>
            </el-link>
            <span v-else class="text-overflow-ellipsis " style="width: 100%;display: inline-block"
                   :title="driverName">{{driver.FirstName}} {{driver.LastName}}</span>
        </div>
    </div>
</template>

<script>
    import driver from "@/store/module/driver";

    export default {
        name: "DriverAvatarNameRight",
        props: {
            driver: {type: Object, default: null},
            isShowDelete: {
                type: Boolean, default: false
            },
            sizeType: {
                type: String, default: 'small'
            },
            isLink: {
                type: Boolean, default: true
            }
        },
        mounted() {
            this.getSize();
        },
        data() {
            return {
                size: 0,
                sizeClass: "avatar-area-small-size"
            }
        },
        computed:{
            driverName(){
                return this.driver.FirstName + " " + this.driver.LastName
            }
        },
        methods: {
            getSize() {
                switch (this.sizeType) {
                    case 'big':
                        this.size = 54;
                        this.sizeClass = "avatar-area-big-size"
                        break;
                    case 'middle':
                        this.size = 48;
                        this.sizeClass = "avatar-area-middle-size"
                        break;
                    case 'small':
                        this.size = 32
                        this.sizeClass = "avatar-area-small-size"
                        break;


                }
            },
            getPhoto(paths) {
                if (paths && paths.length > 0) {
                  //  console.log(this.$baseURL + paths[0])
                    return this.$baseURL + paths[0];
                }
                return null;
            },
            viewDriver(driver) {
                this.$emit("deleteDriver", driver);
            }
        }
    }
</script>


<style scoped>

    .avatar-area-big-size div, .avatar-area-small-size div, .avatar-area-middle-size div {
        float: left;
        vertical-align: middle;
        margin-right: 5px;
        display: inline-block;
    }

    .avatar-area-big-size > div:first-child {
        width: 54px;
        height: 54px;
        margin-right: 12px;
    }

    .avatar-area-middle-size > div:first-child {
        width: 48px;
        height: 48px;
        margin-right: 12px;
    }

    .avatar-area-small-size > div:first-child {
        width: 32px;
        height: 32px;
        margin-right: 12px;
    }

    .avatar-area-big-size > div:last-child {
        width: calc(100% - 72px);
        height: 54px;
        line-height: 54px;
    }

    .avatar-area-middle-size > div:last-child {
        width: calc(100% - 65px);
        height: 48px;
        line-height: 48px;
    }

    .avatar-area-small-size > div:last-child {
        width: calc(100% - 50px);
        height: 32px;
        line-height: 32px;
    }

    .avatar-name-right-area .face-id-icon{
        position: relative;
        top: -25px;
        right: 2px;
        font-size: 12px;
    }



    >>> .el-avatar img {
        width: 100%;
    }

    >>> .el-link {
        width: 100%;
    }

    >>> .el-link--inner {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
