<template>
    <el-form ref="vehicleform" :model="vehicleInfo" :rules="rules" class="management-content" label-width="145px"
             label-position="left">
        <table class="management-table">
            <thead>
            <td colspan="3" class="management-content-title"> {{$t('vehicle.vehicleInformation')}}</td>
            </thead>
            <tr>
                <td>
                    <el-form-item prop="Name" :label="$t('vehicle.vehicleName')" :label-width="width.column1">
                        <el-input v-model="vehicleInfo.Name" size="small" class="text-value-input"></el-input>
                    </el-form-item>
                </td>
                <td>
                    <el-form-item prop="Id" :label="$t('vehicle.plateNumber')" :label-width="width.column2">
                        <el-input v-model="vehicleInfo.Id" size="small" class="text-value-input"
                                  disabled=""></el-input>
                    </el-form-item>
                </td>
                <td>
                    <el-form-item :label="$t('vehicle.fleet')" prop="Fleet" :label-width="width.column3">
                        <el-select v-model="vehicleInfo.Fleet" value-key="Id" size="small"
                                   class="text-value-input-append"
                        >
                            <el-option v-for="(item) in fleetList"
                                       :value="item"
                                       :label="item.Name"
                                       :key="item.Id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                </td>
            </tr>
            <tr>
                <td>
                    <el-form-item :label="$t('vehicle.vehicleModel')" prop="Model" :label-width="width.column1">
                        <el-input v-model="vehicleInfo.Model" size="small"
                                  class="text-value-input"></el-input>
                    </el-form-item>
                </td>

                <td>
                    <el-form-item :label="$t('vehicle.fuelTankCapacity')" prop="FuelTankCapacityShow"
                                  :label-width="width.column2">
                        <el-input v-model.trim="fuelTankCapacity" size="small"
                                  class="text-value-input-append">
                            <template slot="append">{{unitLiter}}</template>
                        </el-input>
                    </el-form-item>
                </td>
                <td>
                    <el-form-item prop="FuelType" :label="$t('vehicle.fuelType')" :label-width="width.column3">
                        <el-input v-model="vehicleInfo.FuelType" size="small" class="text-value-input"></el-input>
                    </el-form-item>
                </td>
                <!--<td>-->
                <!--<el-form-item label="SIM Card" label-width="80px">-->
                <!--<el-input v-model="vehicleInfo.SIM" size="small" class="text-value-input"></el-input>-->
                <!--</el-form-item>-->
                <!--</td>-->
            </tr>
            <tr>
                <td>
                    <el-form-item :label="$t('vehicle.vehicleWeight')" prop="Weight" :label-width="width.column1">
                        <el-select size="small" class="text-value-input" v-model="vehicleInfo.Weight"
                        >
                            <el-option
                                    v-for="item in vehicleWeightOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </td>
                <td>
                    <el-form-item prop="DisplacementShow" :label="$t('vehicle.displacement')"
                                  :label-width="width.column2">
                        <el-input v-model.trim="displacement" size="small" class="text-value-input-append">
                            <template slot="append">{{unitLCC}}</template>
                        </el-input>
                    </el-form-item>
                </td>
                <td></td>
            </tr>
            <tr>
                <td colspan="3">
                    <el-form-item class="license-area" :label-width="width.column1">
                        <div slot="label">{{$t('vehicle.vehicleLicense')}}</div>
                        <v-upload v-if="vehicleInfo.LicensePath" :file="getImagePath(vehicleInfo.LicensePath)"
                                  @selectFile="selectLicense"></v-upload>
                        <v-upload v-else @selectFile="selectLicense"></v-upload>
                    </el-form-item>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <el-form-item class="driver-area" :label-width="width.column1">
                        <div slot="label">{{$t('vehicle.permittedDrivers')}}</div>
                        <v-driver-mult-select :default-drivers="vehicleInfo.Drivers"
                                              @selectDriver="handleSelectDriver"></v-driver-mult-select>
                    </el-form-item>

                </td>
            </tr>
        </table>

        <!--<td class="vehicle-img">-->
        <!--<v-upload :file-list="vehicleInfo.vehicleImg" ></v-upload>-->
        <!--</td>-->
        <table class="management-table" v-if="!isLoadingData">
            <thead>
            <td colspan="3" class="management-content-title">{{$t('vehicle.AIFeatures')}}</td>
            </thead>
            <tr v-if="!isM810OrM820">
                <td>
                    <el-form-item :label="$t('vehicle.vehicleHeight')" :label-width="width.column1"
                                  prop="VehicleHeightShow">
                        <el-input size="small" class="text-value-input-extra" :disabled="vehicleInfo.Device.ADASCalibrated"
                                  v-model="vehicleHeight" @blur="cameraHeightChange">
                            <template slot="append">{{unitCm}}</template>
                        </el-input>
                    </el-form-item>
                </td>
                <td>
                    <el-form-item :label="$t('vehicle.distanceFromCameraToRoof')"
                                  :label-width="width.column2_extra"
                                  prop="CameraHeightShow">
                        <el-input size="small" class="text-value-input-extra" :disabled="vehicleInfo.Device.ADASCalibrated"
                                  v-model="cameraHeight">
                            <template slot="append">{{unitCm}}</template>
                        </el-input>
                    </el-form-item>
                </td>
                <td v-if="!originalVehicleData.isM800">
                    <el-form-item :label="$t('vehicle.driverSeat')" prop="" :label-width="width.column3">
                        <el-select size="small" class="text-value-input" v-model="vehicleInfo.DriverSide"
                                   :disabled="vehicleInfo.Device.DMSCalibrated"
                        >
                            <el-option :value="0" :label="$t('vehicle.left')"></el-option>
                            <el-option :value="1" :label="$t('vehicle.right')"></el-option>
                        </el-select>
                    </el-form-item>

                </td>
            </tr>
            <tr v-if="!isM810OrM820">
                <td>
                    <el-form-item :label="$t('vehicle.vehicleWidth')" :label-width="width.column1"
                                  prop="VehicleWidthShow">
                        <el-input size="small" class="text-value-input-extra" :disabled="vehicleInfo.Device.ADASCalibrated"
                                  v-model="vehicleWidth">
                            <template slot="append">{{unitCm}}</template>
                        </el-input>
                    </el-form-item>
                </td>
                <td>
                    <el-form-item :label="$t('vehicle.hoodLength')"
                                  :label-width="width.column2_extra"
                                  prop="HoodLengthShow">
                        <el-input size="small" class="text-value-input-extra" :disabled="vehicleInfo.Device.ADASCalibrated"
                                  v-model="hoodLength">
                            <template slot="append">{{unitCm}}</template>
                        </el-input>
                    </el-form-item>
                </td>
                <td></td>
            </tr>
            <tr v-if="(!isM810OrM820 && !originalVehicleData.isD700S)">
                <td>
                    <el-form-item :label="$t('vehicle.LDW')" :label-width="width.column1" style="min-height: 32px">
                        <el-switch v-model="LDWStatus"
                                   @change="onChangeLDWStatus"></el-switch>
                    </el-form-item>
                </td>

                <td>
                    <el-form-item :label="$t('vehicle.FCW')" prop="" :label-width="width.column2">
                        <el-switch v-model="FCWStatus"
                                   @change="onChangeFCWStatus"></el-switch>
                    </el-form-item>
                </td>
                <td>
                    <el-form-item :label="$t('vehicle.DMS')" prop="" :label-width="width.column3">
                        <el-switch v-model="DMSStatus"
                                   @change="onChangeDMSStatus"></el-switch>
                    </el-form-item>
                </td>
            </tr>
            <tr>
                <td>
                    <el-form-item :label="$t('login.alertLanguage')" :label-width="width.column1" style="min-height: 32px">
                        <el-select size="small" class="text-value-input language-select-style" v-model="vehicleInfo.AlertLanguage">
                            <el-option
                                    v-for="item in language"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </td>
                <td></td>
                <td></td>
            </tr>
        </table>
        <div class="button-row">
            <el-button size="small" class="ok-button" style="width: 150px" @click="onSave" :loading="isSaving">
                {{$t('globalText.save')}}
            </el-button>
            <el-button size="small" class="cancel-button" @click="onCancel">{{$t('globalText.cancel')}}</el-button>
        </div>
    </el-form>
</template>

<script>
    import vUpload from '../../../components/common/Upload'
    import vDriverMultSelect from '../../../components/management/DriverMultSelect'
    import regExp from "../../../assets/js/RegExp"
    import {numRound} from "@/assets/js/Util"

    export default {
        name: "AddVehicle",
        components: {
            vUpload, vDriverMultSelect
        },
        data() {
            var checkFleet = (rule, value, callback) => {
                if (!this.vehicleInfo.Fleet.Id) {
                    callback(new Error('Please select fleet'));
                } else {
                    callback();
                }
            }

            return {
                language: [
                    {
                        value: "en-US",
                        label: "English"
                    },
                    {
                        value: "ja-JP",
                        label: "日本語"
                    },
                    {
                        value: "zh-CN",
                        label: "中文简体"
                    },
                    {
                        value: "zh-TW",
                        label: "中文繁體"
                    }
                ],
                isLoadingData: true,
                AIFeaturesStatus: null,
                LDWStatus: false,
                FCWStatus: false,
                DMSStatus: false,
                isSaving: false,
                rules: {
                    Name: [
                        {
                            required: true,
                            message: this.$t('tips.vehicles.pleaseInputAVehicleName'),
                            trigger: ['change', 'blur']
                        },
                        {
                            min: 1,
                            max: 15,
                            message: this.$t('globalText.aMaximumOf_X_CharactersCanBeUsed', {num: 15}), // 'A maximum of 15 characters can be used',
                            trigger: ['change', 'blur']
                        }
                    ],
                    Id: [
                        {
                            required: true,
                            message: this.$t('tips.vehicles.pleaseInputAPlateNumber'), // 'Please input a plate number',
                            trigger: ['change', 'blur']
                        },
                        {
                            min: 1,
                            max: 10,
                            message: this.$t('globalText.aMaximumOf_X_CharactersCanBeUsed', {num: 10}), // 'A maximum of 10 characters can be used',
                            trigger: ['change', 'blur']
                        },
                        {validator: regExp.checkPlateNumber, trigger: ['change', 'blur']}
                    ],
                    Model: [
                        {
                            max: 15,
                            message: this.$t('globalText.aMaximumOf_X_CharactersCanBeUsed', {num: 15}), // 'A maximum of 15 characters can be used',
                            trigger: ['change', 'blur']
                        }
                    ],
                    Fleet: [
                        {required: true, trigger: 'blur'},
                        {validator: checkFleet, trigger: 'change'}
                    ],
                    FuelType: [
                        {
                            max: 15,
                            message: this.$t('globalText.aMaximumOf_X_CharactersCanBeUsed', {num: 15}), // 'A maximum of 15 characters can be used',
                            trigger: ['change', 'blur']
                        },
                        {validator: regExp.checkFuelType, trigger: ['change', 'blur']}
                    ],
                    FuelTankCapacityShow: [
                        {
                            required: true,
                            message: this.$t('tips.vehicles.pleaseInputTheFuelTankCapacity'), // 'Please input the fuel tank capacity',
                            trigger: ['change', 'blur']
                        },
                        {validator: regExp.checkFloatOne, trigger: ['change', 'blur']},
                        {
                            validator: (rule, value, callback) => {
                                regExp.checkRange(rule, value, callback, this.FuelTankCapacityRange.min, this.FuelTankCapacityRange.max);

                            }, trigger: ['change', 'blur']
                        },
                    ],
                    Weight: [
                        {
                            required: true,
                            message: this.$t('tips.vehicles.pleaseSelectVehicleWeight'), //'Please select vehicle weight',
                            trigger: ['change', 'blur']
                        },
                    ],
                    DisplacementShow: [
                        {
                            required: true,
                            message: this.$t('tips.vehicles.pleaseInputTheEngineDisplacement'), // 'Please input the engine displacement',
                            trigger: ['change', 'blur']
                        },
                        {validator: regExp.checkFloatOne, trigger: ['change', 'blur']},
                        {
                            validator: (rule, value, callback) => {
                                regExp.checkRange(rule, value, callback, this.DisplacementRange.min, this.DisplacementRange.max);
                            }, trigger: ['change', 'blur']
                        },

                    ],
                    VehicleHeightShow: [
                        // {validator: regExp.checkFloatOne, trigger: ['change', 'blur']},
                        {validator: regExp.checkFloatOne, trigger: ['change', 'blur']},
                        {
                            validator: (rule, value, callback) => {
                                regExp.checkRange(rule, value, callback, this.VehicleHeightRange.min, this.VehicleHeightRange.max);
                            }, trigger: ['change', 'blur']
                        },

                    ],
                    CameraHeightShow: [
                        // {validator: regExp.checkFloatOne, trigger: ['change', 'blur']},
                        {validator: regExp.checkFloatOne, trigger: ['change', 'blur']},
                        {
                            validator: (rule, value, callback) => {
                                var min = this.CameraHeightRange.min;
                                var max = this.CameraHeightRange.max;
                                if (!value) callback();
                                else {
                                    var val = parseFloat(value);
                                    if (this.vehicleInfo.VehicleHeightShow === null || this.vehicleInfo.VehicleHeightShow === "") {
                                        let tips = this.$t('tips.vehicles.pleaseInputTheVehicleHeight'); // "Please input the vehicle height"
                                        callback(new Error(tips));
                                    } else if (!this.isVehicleHeightInRange()) {
                                        let tips = this.$t('tips.vehicles.pleaseCorrectTheVehicleHeight'); // Please correct the vehicle height
                                        callback(new Error(tips));
                                    } else if (min == 0 && max == 0 && val != 0) {
                                        callback(new Error("The distance should be 0"));
                                    } else if (val < min || val > max) {
                                        let tips = this.$t('tips.vehicles.theDistanceShouldBeBetween_X_Y', {
                                            min: min,
                                            max: max
                                        }); // 'The distance should be between ' + min + ' to ' + max)
                                        callback(new Error(tips));
                                    } else {
                                        callback();
                                    }
                                }

                            }, trigger: ['change', 'blur']
                        },

                    ],
                    VehicleWidthShow: [
                        {validator: regExp.checkFloatOne, trigger: ['change', 'blur']},
                        {
                            validator: (rule, value, callback) => {
                                regExp.checkRange(rule, value, callback, this.VehicleWidthRange.min, this.VehicleWidthRange.max);
                            }, trigger: ['change', 'blur']
                        },

                    ],
                    HoodLengthShow: [
                        {validator: regExp.checkFloatOne, trigger: ['change', 'blur']},
                        {
                            validator: (rule, value, callback) => {
                                regExp.checkRange(rule, value, callback, this.HoodLengthRange.min, this.HoodLengthRange.max);
                            }, trigger: ['change', 'blur']
                        },

                    ],

                },
                unAssignedFleet: {
                    Id: "UnassignedVehicles",
                    Name: this.$t('fleet.unassignedVehicles') // "Unassigned Vehicles"
                },
                vehicleWeightOptions: [],
                FuelTankCapacityRange: {
                    min: 1,
                    max: 2000
                },
                DisplacementRange: {
                    min: 0.1,
                    max: 100.0
                },
                WeightRange: {
                    min: 1.8,
                    max: 3.5
                },
                VehicleHeightRange: {
                    min: 130.0,
                    max: 500.0
                },
                CameraHeightRange: {
                    min: 0,
                    max: 370.0
                },
                VehicleWidthRange: {
                    min: 100.0,
                    max: 300.0
                },
                HoodLengthRange: {
                    min: 0,
                    max: 300.0
                },
                vehicleInfo: {
                    Name: "",
                    Id: '',
                    Fleet: {
                        Id: "", Name: "",
                    },
                    Model: '',
                    Capacity: '',
                    DriverIds: [],
                    Drivers: [],
                    Status: '',
                    FuelTankCapacity: null,
                    FuelTankCapacityShow: null,
                    FuelType: '',
                    SIM: '',
                    VIN: '',
                    DeviceId: '',
                    LicensePath: '',
                    BusinessPermitsPath: '',
                    Weight: '',
                    Displacement: null,
                    DisplacementShow: null,
                    VehicleHeight: null,
                    VehicleHeightShow: null,
                    VehicleWidth: null,
                    VehicleWidthShow: null,
                    HoodLength: null,
                    HoodLengthShow: null,
                    CameraHeight: null,
                    CameraHeightShow: null,
                    DriverSide: 0,
                    HasDevice: false,
                    AlertLanguage: this.$i18n.locale,
                    Device: {
                        LDW: false,
                        FCW: false,
                        DMS: false,
                        ADASCalibrated: false,
                        DMSCalibrated: false,
                        DeviceModel: ''
                    }

                },
                originalVehicleData: null,
                fontSize: "",
                width: {
                    column1: '128px',
                    column2: '151px',
                    column2_extra: '215px',
                    column3: '92px'
                }

            }

        },
        computed: {
            isM810OrM820(){
              return this.originalVehicleData.isM810 || this.originalVehicleData.isM820;
            },
            fleetList: function () {
                let list = this.$store.getters.getAllFleets(true);
                let newList = [];
                if (list) {
                    list.forEach(item => {
                        if (item.Id !== this.unAssignedFleet.Id && item.Name !== this.unAssignedFleet.Name) {
                            newList.push({
                                Id: item.Id,
                                Name: item.Name
                            });
                        }
                    });
                    newList.push(this.unAssignedFleet);
                }
                return newList;
            },
            unitLiter() {
                return this.$unit.getLUnit(this);
            },
            currentUnitType() {
                return this.$store.getters.unit;
            },
            unitLCC() {
                return this.$unit.getLOrCIDUnit(this);
            },
            unitCm() {
                return this.$unit.getCmUnit(this);
            },

            fuelTankCapacity: {
                get() {
                    return this.vehicleInfo.FuelTankCapacityShow;
                },
                set(newValue) {
                    this.vehicleInfo.FuelTankCapacity = newValue;
                    this.vehicleInfo.FuelTankCapacityShow = newValue;
                }
            },
            displacement: {
                get() {
                    return this.vehicleInfo.DisplacementShow;
                },
                set(newValue) {
                    this.vehicleInfo.Displacement = newValue;
                    this.vehicleInfo.DisplacementShow = newValue;
                }
            },
            vehicleHeight: {
                get() {
                    return this.vehicleInfo.VehicleHeightShow;
                },
                set(newValue) {
                    this.vehicleInfo.VehicleHeight = newValue;
                    this.vehicleInfo.VehicleHeightShow = newValue;
                }
            },
            cameraHeight: {
                get() {
                    return this.vehicleInfo.CameraHeightShow;
                },
                set(newValue) {
                    this.vehicleInfo.CameraHeight = newValue;
                    this.vehicleInfo.CameraHeightShow = newValue;
                }
            },
            vehicleWidth: {
                get() {
                    console.log(this.vehicleInfo.VehicleWidthShow);
                    return this.vehicleInfo.VehicleWidthShow;
                },
                set(newValue) {
                    this.vehicleInfo.VehicleWidth = newValue;
                    this.vehicleInfo.VehicleWidthShow = newValue;
                }
            },
            hoodLength: {
                get() {
                    console.log(this.vehicleInfo.HoodLengthShow);
                    return this.vehicleInfo.HoodLengthShow;
                },
                set(newValue) {
                    this.vehicleInfo.HoodLength = newValue;
                    this.vehicleInfo.HoodLengthShow = newValue;
                }
            }
        },
        watch: {
            '$store.getters.screen'() {
                this.fontSize = getComputedStyle(window.document.body)['font-size'];
            },
            '$route.path'() {
                this.getVehicleInfo();
            },
            currentUnitType: {
                immediate: true,
                handler: function (newUnit, oldUnit) {
                    if (newUnit === null) return;
                    this.updateFuelTankCapacity(oldUnit, newUnit);
                    this.updateDisplacement(oldUnit, newUnit);
                    this.updateFuelTankCapacityRange();
                    this.updateDisplacementRange();
                    this.updateWeightRange();
                    this.updateCameraHeight(oldUnit, newUnit);
                    this.updateVehicleHeight(oldUnit, newUnit);
                    this.updateVehicleWidth(oldUnit, newUnit);
                    this.updateHoodLength(oldUnit, newUnit);
                    this.updateVehicleHeightRange();
                    this.updateCameraHeightRange();
                    this.updateVehicleWidthRange();
                    this.updateHoodLengthRange();
                }

            },
            fontSize(val) {
                switch (val) {
                    case '14px':
                        this.width = {
                            column1: '128px',
                            column2: '151px',
                            column2_extra: '215px',
                            column3: '96px'
                        }
                        break;
                    case '15px':
                        this.width = {
                            column1: '128px',
                            column2: '150px',
                            column2_extra: '215px',
                            column3: '98px'
                        }
                        break;
                    case '16px':
                        this.width = {
                            column1: '136px',
                            column2: '159px',
                            column2_extra: '229px',
                            column3: '104px'
                        }
                        break;
                    case '18px':
                        this.width = {
                            column1: '152px',
                            column2: '176px',
                            column2_extra: '256px',
                            column3: '115px'
                        }
                        break;

                }
            },
        },
        created() {
            this.getVehicleInfo();
            this.fontSize = getComputedStyle(window.document.body)['font-size']
        },
        methods: {
            onChangeLDWStatus(value) {
                if (!this.vehicleInfo.Device.ADASCalibrated) {
                    //ADAS未校准，弹出提示框
                    this.$customAlert(this.$t('popUpMsg.vehicles.completeADAS'))
                        .then(() => {
                            this.LDWStatus = false;
                        })
                        .catch(() => {
                            this.LDWStatus = false;
                        })
                } else {
                    this.vehicleInfo.Device.LDW = value;
                }
            },
            onChangeFCWStatus(value) {
                if (!this.vehicleInfo.Device.ADASCalibrated) {
                    //ADAS未校准，弹出提示框
                    this.$customAlert(this.$t('popUpMsg.vehicles.completeADAS'))
                        .then(() => {
                            this.FCWStatus = false;
                        })
                        .catch(() => {
                            this.FCWStatus = false;
                        })
                } else {
                    //ADAS已校准
                    if (this.originalVehicleData.isD700) {
                        if (value) {
                            if (this.vehicleInfo.Device.DMSCalibrated) {
                                //DMS已校准
                                if (this.vehicleInfo.Device.DMS) {
                                    //关闭DMS
                                    this.DMSStatus = false;
                                    this.vehicleInfo.Device.DMS = this.DMSStatus;
                                    //开启FCW
                                    this.FCWStatus = true;//开启switch开关
                                    this.vehicleInfo.Device.FCW = this.FCWStatus;//赋值
                                } else {
                                    this.FCWStatus = true;//开启switch开关
                                    this.vehicleInfo.Device.FCW = this.FCWStatus;//赋值
                                }
                            } else {
                                //DMS未校准
                                this.FCWStatus = true;//开启switch开关
                                this.vehicleInfo.Device.FCW = this.FCWStatus;//赋值
                            }
                        } else {
                            //DMS未校准
                            this.FCWStatus = false;//关闭switch开关
                            this.vehicleInfo.Device.FCW = this.FCWStatus;//赋值
                        }
                    } else {
                        this.FCWStatus = value;
                        this.vehicleInfo.Device.FCW = this.FCWStatus;
                    }

                }
            },
            onChangeDMSStatus(value) {
                if (!this.vehicleInfo.Device.DMSCalibrated) {
                    //DMS未校准，弹出提示框
                    this.$customAlert(this.$t('popUpMsg.vehicles.completeDMS'))
                        .then(() => {
                            this.DMSStatus = false;
                        })
                        .catch(() => {
                            this.DMSStatus = false;
                        })
                } else {
                    //DMS已校准
                    if (this.originalVehicleData.isD700) {
                        if (value) {
                            if (this.vehicleInfo.Device.ADASCalibrated) {
                                if (this.vehicleInfo.Device.FCW) {
                                    //关闭FCW
                                    this.FCWStatus = false;
                                    this.vehicleInfo.Device.FCW = this.FCWStatus;
                                    //开启DMS
                                    this.DMSStatus = true;
                                    this.vehicleInfo.Device.DMS = this.DMSStatus;
                                } else {
                                    //开启DMS
                                    this.DMSStatus = true;
                                    this.vehicleInfo.Device.DMS = this.DMSStatus;
                                }
                            } else {
                                //开启DMS
                                this.DMSStatus = true;
                                this.vehicleInfo.Device.DMS = this.DMSStatus;
                            }
                        } else {
                            this.DMSStatus = false;
                            this.vehicleInfo.Device.DMS = this.DMSStatus;
                        }
                    } else {
                        this.DMSStatus = value;
                        this.vehicleInfo.Device.DMS = this.DMSStatus;
                    }
                }
            },
            handleSelectDriver(data) {
                this.vehicleInfo.DriverIds = data.map((item) => {
                    return item.Id;
                })
                console.log("this.vehicleInfo.DriverIds" + this.vehicleInfo.DriverIds);
            },
            getVehicleInfo: function () {
                this.$serverApi.vehicles.getVehicle(this.$route.query.id, (data) => {
                    let fuelTankCapacity = this.$unit.convertLiterUnit(data.FuelTankCapacity);
                    let displacement = this.$unit.convertDisplacementByUnit(data.Displacement);
                    let vehicleHeight = this.$unit.convertCmByUnit(data.ADASHeight ? data.ADASHeight * 100 : null),
                        cameraHeight = this.$unit.convertCmByUnit(data.ADASDistance ? data.ADASDistance * 100 : null);
                    let vehicleWidth = this.$unit.convertCmByUnit(data.ADASWidth ? data.ADASWidth * 100 : null);
                    let hoodLength = this.$unit.convertCmByUnit(data.ADASHoodLength ? data.ADASHoodLength * 100 : null);
                    let vehicleHeightShow = vehicleHeight === null ? null : numRound(vehicleHeight, 1);
                    let cameraHeightShow = cameraHeight === null ? null : numRound(cameraHeight, 1);
                    let vehicleWidthShow = vehicleWidth === null ? null : numRound(vehicleWidth, 1);
                    let hoodLengthShow = hoodLength === null ? null : numRound(hoodLength, 1);
                    let alertLanguage = data.AlertLanguage || this.$i18n.locale;
                    this.vehicleInfo = {
                        Name: data.Name,
                        Id: data.Id,
                        Fleet: data.Fleet ? data.Fleet : this.unAssignedFleet,
                        Model: data.Model,
                        DriverIds: data.DriverIds,
                        Drivers: data.Drivers,
                        FuelTankCapacity: fuelTankCapacity,
                        FuelTankCapacityShow: numRound(fuelTankCapacity, 1),
                        FuelType: data.FuelType,
                        Weight: data.Weight,
                        Displacement: displacement,
                        DisplacementShow: numRound(displacement, 1),
                        VehicleHeight: vehicleHeight,
                        VehicleHeightShow: vehicleHeightShow,
                        VehicleWidth: vehicleWidth,
                        VehicleWidthShow: vehicleWidthShow,
                        HoodLength: hoodLength,
                        HoodLengthShow: hoodLengthShow,
                        CameraHeight: cameraHeight,
                        CameraHeightShow: cameraHeightShow,
                        DriverSide: data.DriverLeftRight,
                        HasDevice: data.HasDevice,
                        AlertLanguage: alertLanguage,
                        Device: {
                            LDW: !!(data.Device && data.Device.LDW),
                            FCW: !!(data.Device && data.Device.FCW),
                            DMS: !!(data.Device && data.Device.DMS),
                            DeviceModel: data.Device ? data.Device.Model ? data.Device.Model.split('_')[0] : "" : "",
                            ADASCalibrated: !!(data.Device && data.Device.ADASCalibrated),
                            DMSCalibrated: !!(data.Device && data.Device.DMSCalibrated),
                        }

                    };
                    this.originalVehicleData = data;
                    if (data.LicensePath) {
                        this.vehicleInfo.LicensePath = data.LicensePath
                    }
                    this.updateCameraHeightRange();
                    this.setADASAndDMSStatus();
                    this.$store.commit("setCurrentVehicle", data);
                    this.isLoadingData = false;
                })
            },
            setADASAndDMSStatus() {
                this.LDWStatus = this.vehicleInfo.Device.ADASCalibrated && this.vehicleInfo.Device.LDW;
                this.FCWStatus = this.vehicleInfo.Device.ADASCalibrated && this.vehicleInfo.Device.FCW;
                this.DMSStatus = this.vehicleInfo.Device.DMSCalibrated && this.vehicleInfo.Device.DMS;
                //记录原始AI开关值
                this.AIFeaturesStatus = {
                    LDW: this.LDWStatus,
                    FCW: this.FCWStatus,
                    DMS: this.DMSStatus
                }
            },
            updateVehicleHeight(oldUnit, newUnit) {
                if (this.vehicleInfo.VehicleHeight) {
                    this.vehicleInfo.VehicleHeight = this.$unit.convertCmByUnit(this.vehicleInfo.VehicleHeight, oldUnit, newUnit);
                    this.vehicleInfo.VehicleHeightShow = numRound(this.vehicleInfo.VehicleHeight, 1);
                }
            },
            updateVehicleWidth(oldUnit, newUnit) {
                if (this.vehicleInfo.VehicleWidth) {
                    this.vehicleInfo.VehicleWidth = this.$unit.convertCmByUnit(this.vehicleInfo.VehicleWidth, oldUnit, newUnit);
                    this.vehicleInfo.VehicleWidthShow = numRound(this.vehicleInfo.VehicleWidth, 1);
                }
            },
            updateHoodLength(oldUnit, newUnit) {
                if (this.vehicleInfo.HoodLength) {
                    this.vehicleInfo.HoodLength = this.$unit.convertCmByUnit(this.vehicleInfo.HoodLength, oldUnit, newUnit);
                    this.vehicleInfo.HoodLengthShow = numRound(this.vehicleInfo.HoodLengthShow, 1);
                }
            },
            updateCameraHeight(oldUnit, newUnit) {
                if (this.vehicleInfo.CameraHeight) {
                    this.vehicleInfo.CameraHeight = this.$unit.convertCmByUnit(this.vehicleInfo.CameraHeight, oldUnit, newUnit);
                    this.vehicleInfo.CameraHeightShow = numRound(this.vehicleInfo.CameraHeight, 1);
                }
            },
            updateFuelTankCapacity(oldUnit, newUnit) {
                if (this.vehicleInfo.FuelTankCapacity) {
                    this.vehicleInfo.FuelTankCapacity = this.$unit.convertLiterUnit(this.vehicleInfo.FuelTankCapacity, oldUnit, newUnit);
                    this.vehicleInfo.FuelTankCapacityShow = numRound(this.vehicleInfo.FuelTankCapacity, 1);
                }
            },
            updateDisplacement(oldUnit, newUnit) {
                if (this.vehicleInfo.Displacement) {
                    this.vehicleInfo.Displacement = this.$unit.convertDisplacementByUnit(this.vehicleInfo.Displacement, oldUnit, newUnit);
                    this.vehicleInfo.DisplacementShow = numRound(this.vehicleInfo.Displacement, 1);
                }
            },
            updateFuelTankCapacityRange() {
                if (this.$unit.isMetric()) {
                    this.FuelTankCapacityRange = {
                        min: 1,
                        max: 2000
                    }
                } else if (this.$unit.isIMPERIAL()) {
                    this.FuelTankCapacityRange = {
                        min: 1,
                        max: 440
                    }
                } else {
                    this.FuelTankCapacityRange = {
                        min: 1,
                        max: 528
                    }
                }
            },
            updateDisplacementRange() {
                this.DisplacementRange = {
                    min: numRound(this.$unit.convertDisplacementByUnit(0.1), 1),
                    max: numRound(this.$unit.convertDisplacementByUnit(100), 1)
                }

            },
            updateVehicleHeightRange() {
                this.VehicleHeightRange = {
                    min: numRound(this.$unit.convertCmByUnit(130.0), 1),
                    max: numRound(this.$unit.convertCmByUnit(500.0), 1),
                }
            },
            updateCameraHeightRange() {
                let data1 = this.$unit.convertCmByUnit(200);
                let data2 = this.$unit.convertCmByUnit(130);
                this.CameraHeightRange = {
                    min: numRound(this.vehicleInfo.VehicleHeightShow > data1 ? this.vehicleInfo.VehicleHeightShow - data1 : 0, 1),
                    max: numRound(this.vehicleInfo.VehicleHeightShow - data2, 1)
                };
                //console.log(this.CameraHeightRange.min + "~" + this.CameraHeightRange.max)
            },
            updateVehicleWidthRange() {
                this.VehicleWidthRange = {
                    min: numRound(this.$unit.convertCmByUnit(100.0), 1),
                    max: numRound(this.$unit.convertCmByUnit(300.0), 1),
                }
            },
            updateHoodLengthRange() {
                this.HoodLengthRange = {
                    min: numRound(this.$unit.convertCmByUnit(0), 1),
                    max: numRound(this.$unit.convertCmByUnit(300.0), 1),
                }
            },
            updateWeightRange() {
                var min = numRound(this.$unit.getTNum(1.8), 1),
                    max = numRound(this.$unit.getTNum(3.5), 1);
                this.vehicleWeightOptions = [
                    {
                        value: 10,
                        label: this.$t('vehicle.under') + min + this.$t('vehicle.t'),

                    },
                    {
                        value: 20,
                        label: min + ' ~ ' + max + this.$t('vehicle.t'),
                    },
                    {
                        value: 30,
                        label: this.$t('vehicle.over') + max + this.$t('vehicle.t'),
                    }
                ]


            },
            selectLicense(imgfiles) {
                if (imgfiles)
                    this.vehicleInfo.LicenseBase64 = imgfiles;
                else
                    this.vehicleInfo.LicenseBase64 = "";
            },
            getSaveInfo() {
                //TODO 接口未指定AlertLanguage字段
                //ADASHeight    Vehicle Height
                let vehicleHeight = this.vehicleInfo.VehicleHeight ? this.vehicleInfo.VehicleHeight / 100 : null;
                //ADASDistance    Distance from Camera to Roof
                let cameraHeight = this.vehicleInfo.CameraHeight ? this.vehicleInfo.CameraHeight / 100 : null;
                //ADASWidth    Vehicle Width
                let vehicleWidth = this.vehicleInfo.VehicleWidth ? this.vehicleInfo.VehicleWidth / 100 : null;
                //ADASHoodLength   Hood Length
                let hoodLength = this.vehicleInfo.HoodLength ? this.vehicleInfo.HoodLength / 100 : null;
                var saveVehicleInfo = {
                    Name: this.vehicleInfo.Name.trim(),
                    Id: this.vehicleInfo.Id.toUpperCase(),
                    FleetId: this.vehicleInfo.Fleet.Id === this.unAssignedFleet.Id ? null : this.vehicleInfo.Fleet.Id,
                    Weight: this.vehicleInfo.Weight,
                    Displacement: this.$unit.convertDisplacementToMetric(this.vehicleInfo.Displacement, this.currentUnitType),
                    //车辆的型号
                    Model: this.vehicleInfo.Model ? this.vehicleInfo.Model : null,
                    FuelTankCapacity: this.$unit.changeLiterUnitToMetric(this.vehicleInfo.FuelTankCapacity, this.currentUnitType),
                    FuelType: this.vehicleInfo.FuelType ? this.vehicleInfo.FuelType : null,
                    DriverIds: this.vehicleInfo.DriverIds,
                    AlertLanguage: this.vehicleInfo.AlertLanguage
                };

                if (!this.isM810OrM820) {
                    //有AI参数
                    saveVehicleInfo.ADASHeight = this.$unit.convertCmUnitToMetric(vehicleHeight, this.currentUnitType);
                    saveVehicleInfo.ADASDistance = this.$unit.convertCmUnitToMetric(cameraHeight, this.currentUnitType);
                    saveVehicleInfo.ADASWidth = this.$unit.convertCmUnitToMetric(vehicleWidth, this.currentUnitType);
                    saveVehicleInfo.ADASHoodLength = this.$unit.convertCmUnitToMetric(hoodLength, this.currentUnitType);
                    if (!this.originalVehicleData.isM800)
                        saveVehicleInfo.DriverLeftRight = this.vehicleInfo.DriverSide;
                    saveVehicleInfo.Device = {
                        LDW: this.vehicleInfo.Device.LDW ? 1 : 0,
                        FCW: this.vehicleInfo.Device.FCW ? 1 : 0,
                        DMS: this.vehicleInfo.Device.DMS ? 1 : 0,
                    };
                    //AI Features开关未变动不传该字段
                    if (this.vehicleInfo.Device.LDW === this.AIFeaturesStatus.LDW) {
                        delete saveVehicleInfo.Device.LDW;
                    }
                    if (this.vehicleInfo.Device.FCW === this.AIFeaturesStatus.FCW) {
                        delete saveVehicleInfo.Device.FCW;
                    }
                    if (this.vehicleInfo.Device.DMS === this.AIFeaturesStatus.DMS) {
                        delete saveVehicleInfo.Device.DMS;
                    }
                }
                saveVehicleInfo.LicenseBase64 = this.vehicleInfo.LicenseBase64;
                return saveVehicleInfo;
            },
            onSave() {
                var successCallback = () => {
                    this.isSaving = false;
                    this.$message(
                        {
                            message: this.$t('globalText.savedSuccessfully'),
                            type: 'success'
                        }
                    )
                    this.$router.go(-1);
                };
                let errorCallback = (error) => {
                    this.isSaving = false;
                    if (error.code === 602) {
                        let msg = this.$t('popUpMsg.vehicles.vehicleName_AlreadyExists', {
                            vehicleName: this.vehicleInfo.Name.trim()
                        }); // `${this.vehicleInfo.Name.trim()} already exists.`
                        this.$customAlert(msg);
                    }
                    if (error.code === 603) {
                        let msg = this.$t('popUpMsg.vehicles.thisPlateNumber_PlateNumber_AlreadyExists', {
                            plateNumber: this.vehicleInfo.Id
                        }); // `This plate number ${this.vehicleInfo.Id} already exists.`
                        this.$customAlert(msg);
                    }
                };
                this.$refs['vehicleform'].validate((valid) => {
                    if (valid) {
                        this.isSaving = true;
                        var saveInfo = this.getSaveInfo();
                        this.$serverApi.vehicles.editVehicle(saveInfo, successCallback, errorCallback);
                    } else {
                        return false;
                    }
                });

            },
            getImagePath(path) {
                return this.$baseURL + path + "?t=" + new Date().getTime();
            },
            onCancel() {
                this.$router.go(-1);
            },
            isVehicleHeightInRange() {
                if (this.vehicleInfo.VehicleHeightShow) {
                    var vehicleHeight = parseFloat(this.vehicleInfo.VehicleHeightShow)
                    if (vehicleHeight >= this.VehicleHeightRange.min && vehicleHeight <= this.VehicleHeightRange.max)
                        return true;
                }
                return false
            },
            cameraHeightChange() {
                this.$refs['vehicleform'].validateField('VehicleHeightShow', (error) => {
                    if (!error)
                        this.updateCameraHeightRange();
                    if (this.vehicleInfo.CameraHeightShow)
                        this.$refs['vehicleform'].validateField('CameraHeightShow');

                })
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "../../../assets/css/management.styl";

    .management-table .text-value-input, .management-table .text-value-input-append, .management-table .text-value-input-extra {
        max-width 280px
        width 100%
    }
    .management-table .language-select-style{
        width 150px !important
    }

    @media only screen and (max-device-width: 1440px) {
        .management-table .text-value-input, .management-table .text-value-input-append {
            max-width 250px
            width 100%
        }

        .management-table .text-value-input-extra {
            width 150px
        }
    }

    .license-area {
        margin: 0 !important;
        padding: 20px 0 20px 0;
    }

    .driver-area {
        margin: 0 !important;
    }

    >>> .el-form-item
        display flex
        align-items center
        .el-form-item__content
            margin-left 0 !important
            line-height normal
            flex: 1
        .el-form-item__label
            line-height normal
        .el-form-item__error
            width 300px

    .uppercase-input >>> .el-input__inner {
        text-transform: uppercase
    }

    >>> .el-switch__core {
        background $color_black_third
    }

</style>
<style>

</style>
