<template>
    <div class="driver-multi-select">

        <v-driver-avatar v-for="item in selectedDrivers" v-bind:key="item.Id" :driver="item"
                         @deleteDriver="deleteDriver" :isShowDelete="true"></v-driver-avatar>

        <div>
            <el-popover
                    placement="right-start"
                    width="250"
                    trigger="click"
                    popper-class="popover-driver"

                    v-model="isShowPopOverver">
                <span class="el-icon-plus add-area" slot="reference"></span>

                <el-checkbox-group v-model="defaultDriverIds" class="checkbox-list scroll-popover" v-if="driveList">
                    <el-checkbox v-for="driver in driveList" :label="driver.Id" :key="driver.Id">
                        <v-driver-avatar-name-right size-type="small" :driver="driver"
                                                    :is-link="false"></v-driver-avatar-name-right>
                    </el-checkbox>
                </el-checkbox-group>
                <div v-else style="text-align: center;width: 100%">{{$t('globalText.noData')}}</div>
                <div class="button-area">
                    <el-button size="small" type="primary" class="ok-button" @click="getSelectedDrivers">{{$t('globalText.oK')}}</el-button>
                </div>

            </el-popover>
        </div>


    </div>
</template>

<script>
    import vDriverAvatar from "../management/DriverAvatar"
    import vDriverAvatarNameRight from "../management/DriverAvatarNameRight"
    import Sort from "@/assets/js/Sort";

    export default {
        name: "DriverMultSelect",
        components: {
            vDriverAvatar,
            vDriverAvatarNameRight
        },
        data() {
            return {
                errorHandler() {
                    return true
                },
                // driveList: [],
                selectedDrivers: [],
                defaultDriverIds: [],
                isShowPopOverver: false,
            }
        },
        props: {
            defaultDrivers: {type: Array, default: null}
        },
        watch: {
            defaultDrivers(newVal) {
                if (newVal) {
                    this.selectedDrivers = [...newVal];
                    this.sortDrivers();
                }
            },
            selectedDrivers(newVal) {
                this.defaultDriverIds = newVal.map((item) => {
                    return item.Id
                });
            }
        },
        computed: {
            driveList() {
                var list = this.$store.getters.sortDriverList;
                // if (list)
                //     list.forEach(item => {
                //         var findItem = this.defaultDrivers.find(data => {
                //
                //             return data.Id === item.Id;
                //         })
                //         if (findItem && findItem.HasFeature){
                //             item.HasFeature = findItem.HasFeature;
                //
                //         }
                //     })
                return list;
            }
        },
        methods: {
            getSelectedDrivers() {
                this.isShowPopOverver = false;
                this.selectedDrivers = this.driveList.filter((item) => {
                    if (this.defaultDriverIds.indexOf(item.Id) > -1)
                        return true;
                    return false;
                });
                //  this.sortDrivers();
                this.$emit("selectDriver", this.selectedDrivers);
            },
            deleteDriver(id) {
                this.selectedDrivers = this.selectedDrivers.filter((item) => item.Id != id);
                this.$emit("selectDriver", this.selectedDrivers);
            },
            sortDrivers() {
                this.selectedDrivers = this.selectedDrivers.sort((itemA, itemB) => {
                    let nameA = (itemA.FirstName || '') + ' ' + (itemA.LastName || '');
                    let nameB = (itemB.FirstName || '') + ' ' + (itemB.LastName || '');
                    return Sort.NameSort(nameA, nameB)
                });
            },
            getPhoto(paths) {
                if (paths && paths.length > 0) {
                    //   console.log(this.$baseURL + paths[0])
                    return this.$baseURL + paths[0];
                }
                return null;
            }
        },
        created() {
            this.$serverApi.driver.driverList((data) => {
                // this.$store.commit("updateDriverList",data);
            })
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .driver-multi-select {
        height 125px
        padding-top 10px
    }

    .driver-multi-select .photo {
        height: 54px;
        width: 54px;
    }

    .driver-multi-select > div {
        display: inline-block;
        float: left;
        margin-right: 24px;
    }

    .driver-multi-select .add-area {
        width: 54px;
        height: 54px;
        line-height: 54px;
        text-align: center;
        background-color: $color_gray_second;
        color: $color_black_second;
        font-size: 24px;
        cursor: pointer;
        border-radius: 50px;
        margin-top 50%
    }

    .driver-multi-select .add-area:hover {
        color: $color_blue_second
    }

    .checkbox-list {
        width: calc(100% -20px)
        height: 350px;
        margin: 10px;
        overflow-y: auto;
        overflow-x: hidden
        text-align: left;
    }

    .checkbox-list >>> .el-checkbox .el-checkbox__label {
        width calc(100% - 20px)
        padding-left 10px
        height 20px
    }

    .button-area {
        text-align: center;
        margin-top: 20px;
    }

    .checkbox-list >>> .el-checkbox {
        display: block;
        line-height: 45px;
        margin-right: 5px;
    }

    /*.popover-driver .popper__arrow {*/
    /*!*border-right-color: #98abbd;*!*/
    /*border: none;*/
    /*}*/

    /*.popover-driver .popper__arrow::after {*/
    /*border: none;*/
    /*}*/

</style>
<style>
    .el-popover {
        padding: 10px 0 10px 10px !important;
    }

</style>
