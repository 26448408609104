<template>

    <div class="avatar-area" >
        <el-avatar :src="getPhoto(driver.OtherPictures)" :size="54" fit="cover" @click.native="detailInfo">
            <img src="../../assets/image/default-photo.svg"/>
        </el-avatar>
        <div class="face-id-icon" v-if="driver.HasFeature"><span class="via-faceID"></span></div>
        <div class="delete-icon" v-if="isShowDelete"><span class="el-icon-remove" @click="deleteDriver(driver.Id)"></span>
        </div>
        <div class="driver-name text-overflow-ellipsis" @click="detailInfo" :title="driverName" :style="{marginTop:isShowDelete?'5px':'0px'}">{{driver.FirstName}} {{driver.LastName}}</div>
    </div>


</template>

<script>
    export default {
        name: "DriverAvatar",
        props: {
            driver: {type: Object, default: null},
            isShowDelete: {
                type: Boolean, default: false
            },
        },
        computed:{
            driverName(){
                return this.driver.FirstName + " " + this.driver.LastName
            }
        },
        methods: {
            getPhoto(paths) {
                if (paths && paths.length > 0) {
                   // console.log(this.$baseURL + paths[0])
                    return this.$baseURL + paths[0];
                }
                return null;
            },
            deleteDriver(id) {
                this.$emit("deleteDriver", id);
            },
            detailInfo(event){
                event.cancelBubble=true;
                this.$router.push({name: "viewDriver", query: {id: this.driver.Id}}).catch();
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .avatar-area {
        position: relative;
        width: 100px;
        line-height: 14px;
        padding 15px 0
        text-align: center;
        display: inline-block;
        float: left;
    }
    .avatar-area >>> .el-avatar img{
        cursor pointer;
    }

    .avatar-area .driver-name {
        max-width: 100px;
        line-height: 20px;
        cursor pointer
    }

    .avatar-area .delete-icon {
        position: absolute;
        top: 55px;
        left: 70px;
        font-size: 16px;
        cursor: pointer;
        color $color_black_third
    }
    .avatar-area .face-id-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 18px;

    }

    .avatar-area .delete-icon:hover{
        color: $color_blue_second;
    }

</style>
